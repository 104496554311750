.views{
    flex-grow: 8;
    text-align: left;
}

.viewList{
    display:flex;
    gap: 5px;
}
.viewItem{

}

.viewsUpload{

}

