.sketchfabViewer{
    flex-basis: 100%;
    margin: 15px;
}
.sketchfabViewerHeader{
    flex-basis: 70%;
}

.camerasAndViews{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    gap:10px;
    align-items: stretch;
}

.controls{
    display:flex;
}

.debug{
    font-size: small;
}

.selected{
    background-color: lightgreen;
}

.unselected{
    background-color: gray;
}