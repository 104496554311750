.cameraContainer{
    flex-grow: 4;
}

.cameraHeader{
    text-align: left;
}

.camerasList{
    margin-top:15px;
    display:flex;
    align-items: flex-end;
    gap:5px;

}

.cameraItem{

}

