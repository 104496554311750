.constructs{
    text-align: left;
}

.constructsList{
    display:flex;
    flex-wrap: wrap;
}

.constructItem{
    flex-basis: 20%;
}